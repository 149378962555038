import {useContext} from 'react'
import {UserContext} from '../../contexts/user-context';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';

function UserMenu() {
  const context = useContext(UserContext);
  const avatarUrl = "https://ui-avatars.com/api/?background=random&name="+context.user.name

  function handleLogout(event) {
    event.preventDefault();
    context.setUser(0, '', '');
    context.setIsLogged(false);
  }

  return (
    <Dropdown as={NavItem} id="userMenu" className="d-flex justify-content-center align-items-center">
      <Dropdown.Toggle as={NavLink} className="d-flex align-items-center">
        <Image
          alt={context.user.name}
          src={avatarUrl}
          fluid={true}
          roundedCircle={true}
          className="avatar me-3"
        />
        <div className="caption">
          <h6 className="mb-0 line-height">{context.user.name}</h6>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu align={{ lg: 'end' }}>
        <Card className="shadow-none m-0">
          <Card.Header className="bg-primary">
            <h5 class="mb-0 text-white">Hello {context.user.name}</h5>
            <span className="text-white font-size-12">Disponible</span>
          </Card.Header>
          <Card.Body className="p-0">
            <a class="subcard bg-primary-hover d-flex align-items-center justify-content-between" href="#profile">
              <div class="d-flex align-items-center">
                <div class="rounded card-icon bg-soft-primary">
                  <i class="ri-file-user-line"></i>
                </div>
                <div class="ms-3">
                  <h6 class="mb-0 ">My Profile</h6>
                  <p class="mb-0 font-size-12">View personal profile details.</p>
                </div>
              </div>
            </a>
            <a class="subcard bg-warning-hover d-flex align-items-center justify-content-between" href="#user-profile-edit">
              <div class="d-flex align-items-center">
                <div class="rounded card-icon bg-soft-warning">
                  <i class="ri-profile-line"></i>
                </div>
                <div class="ms-3">
                  <h6 class="mb-0 ">Edit Profile</h6>
                  <p class="mb-0 font-size-12">Modify your personal details.</p>
                </div>
              </div>
            </a>
            <a class="subcard bg-info-hover d-flex align-items-center justify-content-between" href="#user-account-setting">
              <div class="d-flex align-items-center">
                <div class="rounded card-icon bg-soft-info">
                  <i class="ri-account-box-line"></i>
                </div>
                <div class="ms-3">
                  <h6 class="mb-0 ">Account settings</h6>
                  <p class="mb-0 font-size-12">Manage your account parameters.</p>
                </div>
              </div>
            </a>
            <a class="subcard bg-danger-hover d-flex align-items-center justify-content-between" href="#user-privacy-setting">
              <div class="d-flex align-items-center">
                <div class="rounded card-icon bg-soft-danger">
                  <i class="ri-lock-line"></i>
                </div>
                <div class="ms-3">
                  <h6 class="mb-0 ">Privacy Settings</h6>
                  <p class="mb-0 font-size-12">Control your privacy parameters.</p>
                </div>
              </div>
            </a>
            <div className="d-inline-block w-100 text-center p-3">
              <Button variant="primary" title="Sign Out" onClick={handleLogout}>Sign out<i className="ri-login-box-line ms-2"></i></Button>
            </div>
          </Card.Body>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserMenu;