import React, {useState} from 'react'
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import UserMenu  from './UserMenu';
import logo from '../../img/logo_colors.png';

function Header() {
  const [searchTerms, setSearchTerms] = useState('');

  function handleSearchSubmit(event) {
    event.preventDefault();
    
  }
  return (
    <header className="d-flex align-items-center">
      <div className="d-flex flex-fill justify-content-start">
        <Image
          src={logo}
          alt="INVERS"
          fluid={true}
        />
      </div>
      <div className="d-flex flex-fill justify-content-center">
        <Form id="formSearch" onSubmit={handleSearchSubmit}>
          <Form.Group className="position-relative" controlId="formBasicText">
            <Form.Label className="d-none">Search</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Search here..."
              value={searchTerms}
              onChange={e => setSearchTerms(e.target.value)}
            />
            <a class="search-link" href="/socialv/bs5/react/build/"><i class="ri-search-line"></i></a>
          </Form.Group>
        </Form>
      </div>
      <div class="d-flex flex-fill justify-content-end">
        <Navbar variant="primary">
          <Nav as="ul" className="me-auto">
            <li className="d-flex justify-content-center align-items-center">
              <Nav.Link href="#home"><i class="ri-home-line"></i></Nav.Link>
            </li>
            <li className="d-flex justify-content-center align-items-center">
              <Nav.Link href="#friends"><i class="ri-group-line"></i></Nav.Link>
            </li>
            <li className="d-flex justify-content-center align-items-center">
              <Nav.Link href="#notifications"><i class="ri-notification-4-line"></i></Nav.Link>
            </li>
            <li className="d-flex justify-content-center align-items-center">
              <Nav.Link href="#messages"><i class="ri-mail-line"></i></Nav.Link>
            </li>
            <li className="d-flex justify-content-center align-items-center">
              <UserMenu />
            </li>
          </Nav>
        </Navbar>
      </div>
    </header>
  );
}

export default Header;